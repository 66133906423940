import React from 'react';
import {
  Container,
  Header,
  Input,
  Button,
  Message,
  Form
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import uuidvalidate from 'uuid-validate';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Register extends React.Component {
  state = {
    login: '',
    loginError: '',
    displayname: '',
    displaynameError: '',
    password: '',
    passwordError: '',
    token: '',
    redirect: false
  };

  componentDidMount () {
    const { token } = this.props.match.params;
    const validUuid = uuidvalidate(token);

    if (!validUuid) {
      this.setState({
        redirect: true
      });
    }

    this.setState({
      token
    });
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  onSubmit = async e => {
    this.setState({
      loginError: '',
      displaynameError: '',
      passwordError: ''
    });
    const { login, displayname, password, token } = this.state;
    const response = await this.props.mutate({
      variables: { login, displayname, password, token }
    });

    const { ok, errors } = response.data.updateUserRegister;

    if (ok) {
      this.props.history.push('/login');
    } else {
      const err = {};
      if (errors) {
        errors.forEach(({ path, message }) => {
          // err['passwordError'] = 'too long..';
          err[`${path}Error`] = message;
        });
      }

      this.setState(err);
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    // name = "login" --> this.setState({ login: value}) etc..
    this.setState({ [name]: value });
  };

  render () {
    const {
      login,
      displayname,
      password,
      loginError,
      displaynameError,
      passwordError
    } = this.state;

    const { t } = this.props;

    const translateError = errorText => {
      const withNoDigits =
        'chat.error.' + errorText.replace(/[0-9]/g, '').replace(/ +(?= )/g, '');
      var translatedText = t(withNoDigits, {
        count: errorText.replace(/\D/g, '')
      });
      return translatedText;
    };

    const errorList = [];
    if (loginError) errorList.push(translateError(loginError));
    if (displaynameError) errorList.push(translateError(displaynameError));
    if (passwordError) errorList.push(translateError(passwordError));

    // !! means casting to boolean
    // !'' => true
    // !!'' => false
    return (
      <>
        {this.renderRedirect()}
        <div className="centered">
          <Container text>
            <Header style={{ paddingTop: '30px' }} as="h2">
              {t(
                'chat.register.registerHeader',
                '_chat.register.registerHeader'
              )}
            </Header>
            <Form>
              <Form.Field error={!!displaynameError}>
                <label> {t('chat.displayName', '_chat.displayName')}</label>
                <Input
                  name="displayname"
                  onChange={this.onChange}
                  value={displayname}
                  placeholder={t(
                    'chat.register.createYourSelectedDisplayName',
                    '_chat.register.createYourSelectedDisplayName'
                  )}
                  autoComplete="displayname"
                  fluid
                />
              </Form.Field>
              <Form.Field error={!!loginError}>
                <label>{t('chat.userName', '_chat.userName')}</label>
                <Input
                  name="login"
                  onChange={this.onChange}
                  value={login}
                  placeholder={t(
                    'chat.register.createYourSelectedUserName',
                    '_chat.register.createYourSelectedUserName'
                  )}
                  autoComplete="login"
                  fluid
                />
              </Form.Field>
              <Form.Field error={!!passwordError}>
                <label>{t('chat.password', '_chat.password')}</label>
                <Input
                  name="password"
                  onChange={this.onChange}
                  value={password}
                  type="password"
                  placeholder={t(
                    'chat.register.passwordRequirements',
                    '_chat.register.passwordRequirements'
                  )}
                  autoComplete="password"
                  fluid
                />
              </Form.Field>
              <Button onClick={this.onSubmit}>
                {t('chat.register.Register', '_chat.register.Register')}
              </Button>
            </Form>
            {errorList.length ? (
              <Message
                error
                // header={t(
                //   'chat.register.inputError',
                //   { count: errorList.length },
                //   '_chat.register.inputError'
                // )}
                list={errorList}
              />
            ) : null}
          </Container>
        </div>
      </>
    );
  }
}

// const registerMutation = gql`
//   mutation($login: String!, $displayname: String!, $password: String!) {
//     register(login: $login, displayname: $displayname, password: $password) {
//       ok
//       errors {
//         path
//         message
//       }
//     }
//   }
// `;

const updateUserRegisterMutation = gql`
  mutation(
    $token: String!
    $login: String!
    $displayname: String!
    $password: String!
  ) {
    updateUserRegister(
      token: $token
      login: $login
      displayname: $displayname
      password: $password
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export default compose(
  graphql(updateUserRegisterMutation),
  withTranslation()
)(Register);
