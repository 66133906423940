import React from 'react';
import { LinkWithoutPopup } from './LinkWithoutPopup';
import { WithLogo } from './WithLogo';
import { WithModal } from './WithModal';
import { WithPDF } from './WithPDF';
import { WithSource } from './WithSource';

export function displaySubfields (data, outerTopic) {
  if (!data) return null;

  return data.map((d, index) => {
    return (
      <div className="linkContainer" key={`info-${d.url + index}`}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {outerTopic === d.topic ? (
            LinkWithoutPopup(d.text, d.url, d.url_text, d.url_image)
          ) : (
            <div>
              {d.pdf ? (
                WithPDF(d.pdf, d.pdf_title)
              ) : (
                <b>
                  {d.url_image
                    ? WithModal(d.topic, d.url_image)
                    : LinkWithoutPopup(d.topic, d.url, d.url_text, d.url_image)}
                </b>
              )}

              <div>{d.text}</div>
              {d.source ? WithSource(d.url, d.source, d.source_url) : null}
              {d.logo ? WithLogo(d.source_url, d.logo) : null}
            </div>
          )}
        </div>
      </div>
    );
  });
}
