import React from 'react';

import { pdfs, HTMLtarget } from '../..';

export function WithPDF (pdf, title) {
  let pdfSource;
  if (pdf) {
    pdfSource = pdfs[`${pdf}`];
  }

  return (
    <b>
      <a href={pdfSource} target={HTMLtarget}>
        {title}
      </a>
    </b>
  );
}
