import React, {Component} from 'react';
import companyLogo from './../../Assets/logos/companyLogo.png'
import { Image } from 'semantic-ui-react';
import './footer.scss';

class Footer extends Component {
    render () {
        return (
            <div className="container">
                <Image src={companyLogo} style={{width: '3%', height: '3.5rem'}}/>
                <h4>Powered by <a href="https://www.prowellness.com">ProWellness Heath Solutions Oy</a></h4>
            </div>
        )
    }
}

export default Footer
