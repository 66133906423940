import React from 'react';
import { Icon } from 'semantic-ui-react';
import { HTMLtarget, images } from '../..';

export function LinkWithoutPopup (textORtopic, url, url_text, url_image) {
  return WithoutPopup(textORtopic, url, url_text, url_image, false);
}

export function LinkWithoutPopupBold (textORtopic, url, url_text, url_image) {
  return WithoutPopup(textORtopic, url, url_text, url_image, true);
}

function WithoutPopup (textORtopic, url, url_text, url_image, bold = false) {
  if (bold) {
    return (
      <b>
        <a href={url_image ? images[`${url_image}`] : url} target={HTMLtarget}>
          {url_text ? <>{url_text} </> : <>{textORtopic}</>}
          &nbsp;
          <Icon name="external alternate" />
        </a>
      </b>
    );
  }

  return (
    <a href={url_image ? images[`${url_image}`] : url} target={HTMLtarget}>
      {url_text ? <>{url_text} </> : <>{textORtopic}</>}
      &nbsp;
      <Icon name="external alternate" />
    </a>
  );
}
