import React, { Component } from 'react';
import { displayAccordion } from './components/DisplayAccordion';
import { displaySegment } from './components/DisplaySegment';
import jsonDATA from './data/Perheiden_sivu5_Hiilihydraatit';
import carbohydrates from './../Assets/Banner/Carbohydrates.jpg'; // Tell Webpack this JS file uses this image
import { Image } from 'semantic-ui-react';
class Carbohydrates extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleColorChange = e => this.setState({ color: e.target.value });

  handleChange = (e, data) => this.setState(data);

  render () {
    const { activeIndex } = this.state;

    return (
      <>
        <div className="showOnlyOnDesktop">
          <div className="centerOnPage">
            <div className="desktopFlex">
              {displaySegment(jsonDATA, activeIndex, this.handleClick)}
            </div>
          </div>
        </div>
        <div className="showOnlyOnMobile">
          <div className="centerOnPage">
            <Image src={carbohydrates} className="banners" />
            {displayAccordion(jsonDATA, activeIndex, this.handleClick)}
          </div>
        </div>
      </>
    );
  }
}
export default Carbohydrates;
