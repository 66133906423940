import React from 'react';

import { TextArea, Button } from 'semantic-ui-react';
import { withFormik } from 'formik';

import Dropzone from 'react-dropzone';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';

const SendMessage = ({
  values,
  handleChange,
  handleSubmit,
  mutate,
  channelId
}) => (
  <div className=" chat-input">
    <Dropzone
      accept="image/*"
      multiple={false}
      onDrop={async ([file]) => {
        await mutate({
          variables: {
            channelId,
            file
          }
        });
        // console.table(response);
      }}>
      {({ getRootProps, getInputProps }) => (
        <Button
          style={{
            float: 'left',
            backgroundColor: 'transparent',
            marginTop: '1rem'
          }}
          {...getRootProps()}>
          <input {...getInputProps()} />
          <FontAwesomeIcon
            icon={faPlus}
            style={{ color: '#fff', fontSize: '1.25rem' }}
          />
        </Button>
      )}
    </Dropzone>
    <Translation>
      {t => (
        <form>
          <TextArea
            name="message"
            value={values.message}
            onChange={handleChange}
            placeholder={t('chat.writeMessageHere', '_chat.writeMessageHere')}
          />
          <button type="submit" onClick={handleSubmit}>
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{ color: '#fff', fontSize: '1.25rem' }}
            />
          </button>
        </form>
      )}
    </Translation>
  </div>
);

const createFileMessageMutation = gql`
  mutation($channelId: ID!, $file: Upload) {
    createMessage(channelId: $channelId, file: $file)
  }
`;

export default compose(
  graphql(createFileMessageMutation),
  withFormik({
    mapPropsToValues: () => ({ message: '' }),

    handleSubmit: async (
      values,
      { props: { onSubmit }, setSubmitting, resetForm }
    ) => {
      if (!values.message || !values.message.trim()) {
        setSubmitting(false);
        return;
      }

      await onSubmit(values.message);
      resetForm(false);
    }
  })
)(SendMessage);
