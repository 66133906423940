import React from 'react';
import { Route, Redirect, HashRouter } from 'react-router-dom';

import decode from 'jwt-decode';
import smoothscroll from 'smoothscroll-polyfill';

// Top of page
import Layout from '../components/Layout';
// Pages
import Frontpage from '../pages/Frontpage';
import Benefits from '../pages/Benefits';
import SpecialSituations from '../pages/SpecialSituations';
import DiabetesCounceling from '../pages/DiabetesCounceling';
import NewsAndInfo from '../pages/NewsAndInfo';
import ImportantContacts from '../pages/ImportantContacts';
import Carbohydrates from '../pages/Carbohydrates';
import Register from './Register';
import Login from './Login';
import Logout from './Logout';
import ChangePassword from './ChangePassword';
import CreateTeam from './CreateTeam';
import ViewChat from './ViewChat';
import OwnGoals from '../pages/OwnGoals';
import DiabetesFacts from '../pages/DiabetesFacts';
import WidthLayouts from '../routes/WidthLayouts';
import ViewChatMobile from '../routes/ViewChatMobile';
import ViewChatWebsite from '../routes/ViewChatWebsite';

// kick off the polyfill!
smoothscroll.polyfill();

// Configure ApolloClient for GraphQL

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    decode(token);
    const { exp } = decode(refreshToken);
    if (Date.now() / 1000 > exp) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login'
          }}
        />
      )
    }
  />
);

export default () => (
  <>
    <HashRouter>
      <>
        <Layout>
          <Route exact path="/register/:token" component={Register} />
          <Route exact path="/ChangePassword" component={ChangePassword} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Logout" component={Logout} />
          <PrivateRoute path="/CreateTeam" exact component={CreateTeam} />
          <Route exact path="/ViewChat" component={ViewChat} />
          <PrivateRoute
            path="/ViewChatMobile/:teamId?/:channelId?"
            exact
            component={ViewChatMobile}
          />
          <PrivateRoute
            path="/ViewChatWebsite/:teamId?/:channelId?"
            exact
            component={ViewChatWebsite}
          />
          <Route exact path="/" component={WidthLayouts} />
          <Route exact path="/FrontPage" component={Frontpage} />
          <Route exact path="/Benefits" component={Benefits} />
          <Route exact path="/Carbohydrates" component={Carbohydrates} />
          <Route
            exact
            path="/ImportantContacts"
            component={ImportantContacts}
          />
          <Route
            exact
            path="/DiabetesCounceling"
            component={DiabetesCounceling}
          />
          <Route exact path="/NewsAndInfo" component={NewsAndInfo} />
          <Route exact path="/OwnGoals" component={OwnGoals} />
          <Route
            exact
            path="/SpecialSituations"
            component={SpecialSituations}
          />
          <Route exact path="/DiabetesFacts" component={DiabetesFacts} />
        </Layout>
      </>
    </HashRouter>
  </>
);
