// import 'babel-polyfill'; // For IE 11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import moment from 'moment';
// import cookie from 'js-cookie';
import './i18n';
import client from './apollo';
import registerServiceWorker from './registerServiceWorker';
import Routes from './routes';
import 'moment/locale/fi';
import './styling/semantic-ui-css/semantic.css';
// import 'semantic-ui-css/semantic.css';
import './styling/style.scss';
import './styling/chat-app.scss';

cssVars();

// var cookieSetLanguage = cookie.get('language');

// console.log(cookieSetLanguage, 'kieli vaihdettu cookiella');

// cookie.set(
//   'language',
//   window.navigator.userLanguage || window.navigator.language
// );

const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace('./', '')] = require(next);
    return acc;
  }, {});

export const images = importAll(
  require.context('./Assets/images', false, /\.(png|jpe?g|svg)$/)
);

export const logos = importAll(
  require.context('./Assets/logos', false, /\.(png|jpe?g|svg)$/)
);

export const pdfs = importAll(
  require.context('./Assets/pdf', false, /\.(pdf)$/)
);

export const formatDate = timestamp => {
  if (typeof timestamp === 'string') {
    timestamp = parseInt(timestamp);
  } else {
    const DATE_FORMAT = 'ddd MMM DD YYYY HH:mm:ss';
    timestamp = moment(timestamp, DATE_FORMAT);
  }
  return timestamp;
};

export const THIS_IS_PATIENT_CHANNEL = 'thisIsPatientChannel';

// change target to '_blank' to open links in new tab/window
// change targe to '' to open links in same window (default)
export const HTMLtarget = '_blank';

const Root = () => {
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
registerServiceWorker();
