import React from 'react';
import Links from '../Links';
import { DrawerToggleButton } from '../SideDrawer/DrawerToggleButton';
// import finFlag from '../../Assets/icons/flag-finland@2x.png';
// import engFlag from '../../Assets/icons/flag-united-kingdom@2x.png';
import './Toolbar.scss';
import { Sticky } from 'react-uikit3';

export const Toolbar = props => {
  return (
    <>
      <div className="showOnlyOnDesktop">
        <div className="centerOnPage">
          <div className="desktopFlex">
            <Sticky>
              <header className="toolbar">
                <nav className="toolbar__navigation">
                  <div className="toolbar__navigation__middle">
                    <div className="toolbar_navigation-items">
                      <Links
                        changeTextHandler={props.changeTextHandler}
                        closeDrawer={props.closeHandler}
                        contentProvider={props.contentProvider}
                        changeLanguage={props.changeLanguage}
                        style={{ color: '#fff' }}
                      />
                    </div>
                  </div>
                  {/* <div className="toolbar__navigation__right">
                  <div className="changeLanguageFlags">
                    <img
                      onClick={() => props.changeLanguage('fi')}
                      src={finFlag}
                      alt=""
                    />
                    <img
                      onClick={() => props.changeLanguage('en')}
                      src={engFlag}
                      alt=""
                    />
                  </div>
                </div> */}
                  <div className="toolbar__pagename">{props.displayText}</div>
                </nav>
              </header>
            </Sticky>
          </div>
        </div>
      </div>

      <div className="showOnlyOnMobile">
        <header className="toolbar__mobile">
          <nav className="toolbar__navigation" style={{ margin: '4px' }}>
            <div className="toolbar__navigation__left">
              <div className="toolbar__toggle-button">
                <DrawerToggleButton click={props.drawerClickHandler} />
              </div>
              <div
                style={{
                  color: '#fff',
                  marginLeft: '0.5rem',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}>
                OmaDiabetes.fi
              </div>
            </div>

            <div className="toolbar__navigation__middle">
              <div className="toolbar_navigation-items">
                <Links
                  changeTextHandler={props.changeTextHandler}
                  closeDrawer={props.closeHandler}
                  contentProvider={props.contentProvider}
                  changeLanguage={props.changeLanguage}
                />
              </div>
            </div>
            <div className="toolbar__pagename">{props.displayText}</div>
          </nav>
        </header>
      </div>
    </>
  );
};
