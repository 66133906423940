import React from 'react';

import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
// import { ChatItem } from 'react-chat-elements';
// import moment from 'moment';
import { sidebarClose } from './sidebarToggle';

import './Channels.scss';
import { THIS_IS_PATIENT_CHANNEL } from '../../..';
import { Trans, Translation } from 'react-i18next';
// import { sidebarClose } from './sidebarToggle';

const Bubble = ({ on = true }) =>
  on ? <span className="greenSpan">●</span> : '○';

const channel = ({ id, name }, teamId, teamName) => {
  let displayChannelName = name;
  if (name === THIS_IS_PATIENT_CHANNEL) {
    displayChannelName = teamName;
  }

  return (
    <Link key={`channel-${id}`} to={`/ViewChat/${teamId}/${id}`}>
      <li className="SideBarListItem">
        {/* <ChatItem alt={name} title={name} subtitle={name} unread={1} /> */}
        <Bubble /> {displayChannelName}
      </li>
    </Link>
  );
};

// const dmChannel = ({ id, name }, teamId) => (
//   <li className="SideBarListItem" key={`user-${id}`}>
//     <Link to={`/ViewChat/${teamId}/${id}`}>
//       <Bubble /> {name}
//     </Link>
//   </li>
// );

export default ({
  teamName,
  displayName,
  channels,
  dmChannels,
  onAddChannelClick,
  teamId,
  onInvitePeopleClick,
  onDirectMessageClick,
  isOwner
}) => (
  <div className="sidebar-left sidebar-left-closed ChannelWrapper">
    <div className="">
      {/* <h1 className="TeamNameHeader">
        Tiimi: <br />
        {teamName}
      </h1> */}

      {/* <b>Käyttäjä:</b> */}
      <Translation>
        {t => (
          <button
            className="sidebarButtonClose visible"
            onClick={() => sidebarClose('left')}
            title={t('chat.sideBar.close', '_chat.sideBar.close')}>
            <Icon name="chevron left" />
            {t('chat.sideBar.close', '_chat.sideBar.close')}
            {/* <Icon name="close" /> */}
          </button>
        )}
      </Translation>
      {/* <br />
        {displayName} */}
    </div>
    <div>
      <ul className="SideBarList">
        <li className="SideBarListHeader">
          <Trans
            i18nKey="chat.sideBar.conversations"
            defaults="_chat.sideBar.conversations"
          />
        </li>

        {channels.map(c => channel(c, teamId, teamName, displayName))}

        <br />

        {isOwner && (
          <p className="PushLeft" onClick={onAddChannelClick}>
            <Trans
              i18nKey="chat.sideBar.startNewConversation"
              defaults="_chat.sideBar.startNewConversation"
            />
            <Icon name="add circle" />
          </p>
        )}
      </ul>
      <div className="logout PushLeft">
        <Link key="logout" to="/logout">
          <Trans
            i18nKey="chat.sideBar.logOut"
            defaults="_chat.sideBar.logOut"
          />
        </Link>
      </div>
    </div>
    {/* <div>
      <ul className="SideBarList">
        <li className="SideBarListHeader">
          Direct Messages
          <Icon onClick={onDirectMessageClick} name="add circle" />
        </li>
        {dmChannels.map(dmC => dmChannel(dmC, teamId))}
      </ul>
    </div> */}
    {/* {isOwner && (
      <div>
        <a href="#invite-people" onClick={onInvitePeopleClick}>
          + Invite People
        </a>
      </div>
    )} */}
  </div>
);
