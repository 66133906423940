import React, { Component } from 'react';
import {
  Container,
  Header,
  Input,
  Button,
  Form,
  Message
} from 'semantic-ui-react';
import { extendObservable } from 'mobx';

import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import { observer } from 'mobx-react';
import { wsLink } from '../apollo';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class Login extends Component {
  constructor (props) {
    super(props);

    extendObservable(this, {
      login: '',
      password: '',
      errors: {}
    });
  }

  onSubmit = async () => {
    const { login, password } = this;
    const response = await this.props.mutate({
      variables: { login, password }
    });

    // console.log(response);

    const { ok, token, refreshToken, errors } = response.data.login;

    if (ok) {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      wsLink.subscriptionClient.tryReconnect();
      this.props.history.push('/ViewChat');
    } else {
      const err = {};
      errors.forEach(({ path, message }) => {
        // err['passwordError'] = 'too long..';
        err[`${path}Error`] = message;
      });

      this.errors = err;
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    this[name] = value;
  };

  render () {
    // prettier-ignore
    const { login, password, errors: { loginError, passwordError } } = this;
    const { t } = this.props;

    const translateError = errorText => {
      const withNoDigits =
        'chat.error.' + errorText.replace(/[0-9]/g, '').replace(/ +(?= )/g, '');
      var translatedText = t(withNoDigits, {
        count: errorText.replace(/\D/g, '')
      });
      return translatedText;
    };

    const errorList = [];
    if (loginError) errorList.push(translateError(loginError));
    if (passwordError) errorList.push(translateError(passwordError));

    return (
      <>
        <div className="showOnlyOnDesktop">
          <div className="login__desktop">
            <Header
              as="h2"
              className="login__desktop__header"
              style={{ marginTop: '2rem' }}>
              {t('chat.login.loginHeader', '_chat.login.loginHeader')}
            </Header>
            <Container text>
              <Form className="login__form">
                <Form.Field error={!!loginError}>
                  <label>{t('chat.userName', '_chat.userName')}</label>
                  <div>
                    <Input
                      name="login"
                      onChange={this.onChange}
                      value={login}
                      placeholder={t('chat.userName', '_chat.userName')}
                      autoComplete="login"
                      fluid
                      style={{ width: '300px' }}
                    />
                  </div>
                </Form.Field>
                <Form.Field error={!!passwordError}>
                  <label>{t('chat.password', '_chat.password')}</label>
                  <Input
                    name="password"
                    onChange={this.onChange}
                    value={password}
                    type="password"
                    placeholder={t('chat.password', '_chat.password')}
                    autoComplete="current-password"
                    fluid
                    style={{ width: '300px' }}
                  />
                </Form.Field>
                {/* <Grid columns={2} >
                  <Grid.Row style={{fontSize: "90%"}}>
                    <Grid.Column>
                      <label>
                        <input
                          name="remember"
                          type="checkbox"
                          // checked={this.state.remember}
                          // onChange={this.handleInputChange}
                          />
                        Remember me
                      </label>
                    </Grid.Column>
                    <Grid.Column>
                      Forget password?
                    </Grid.Column>
                  </Grid.Row>
                </Grid> */}
                <Button
                  onClick={this.onSubmit}
                  className="login__desktop__button">
                  {t('chat.login.logIn', '_chat.login.logIn')}
                </Button>
              </Form>
              <div className="message">
                {errorList.length ? (
                  <Message
                    error
                    // header={t(
                    //   'chat.login.inputError',
                    //   { count: errorList.length },
                    //   '_chat.login.inputError'
                    // )}
                    list={errorList}
                  />
                ) : null}
              </div>
            </Container>
          </div>
        </div>
        <div className="showOnlyOnMobile">
          <div className="centered">
            <div className="login__mobile" />
            <Container text className="login__mobile__border">
              <Header
                as="h2"
                style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                {t('chat.login.loginHeader', '_chat.login.loginHeader')}
              </Header>
              <Form>
                <Form.Field error={!!loginError}>
                  <label>{t('chat.userName', '_chat.userName')}</label>
                  <Input
                    name="login"
                    onChange={this.onChange}
                    value={login}
                    placeholder={t('chat.userName', '_chat.userName')}
                    autoComplete="login"
                    fluid
                  />
                </Form.Field>
                <Form.Field error={!!passwordError}>
                  <label>{t('chat.password', '_chat.password')}</label>
                  <Input
                    name="password"
                    onChange={this.onChange}
                    value={password}
                    type="password"
                    placeholder={t('chat.password', '_chat.password')}
                    autoComplete="current-password"
                    fluid
                  />
                </Form.Field>
                {/* <Grid columns={2} >
                  <Grid.Row style={{fontSize: "90%"}}>
                    <Grid.Column>
                      <label>
                        <input
                          name="remember"
                          type="checkbox"
                          // checked={this.state.remember}
                          // onChange={this.handleInputChange}
                          />
                        Remember me
                      </label>
                    </Grid.Column>
                    <Grid.Column>
                      Forget password?
                    </Grid.Column>
                  </Grid.Row>
                </Grid> */}
                <Button
                  onClick={this.onSubmit}
                  className="login__mobile__button">
                  {t('chat.login.logIn', '_chat.login.logIn')}
                </Button>
              </Form>
              <div className="message__mobile">
                {errorList.length ? (
                  <Message
                    error
                    // header={t(
                    //   'chat.login.inputError',
                    //   { count: errorList.length },
                    //   '_chat.login.inputError'
                    // )}
                    list={errorList}
                  />
                ) : null}
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const loginMutation = gql`
  mutation($login: String!, $password: String!) {
    login(login: $login, password: $password) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;

export default compose(
  graphql(loginMutation),
  withTranslation()
)(withRouter(Login), observer(Login));
