import React from 'react';
import { HTMLtarget } from '../..';

export function WithSource (url, source, source_url) {
  return (
    <div className="linkContainer__source">
      <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
        Lähde:
        <a href={source_url ? source_url : url} target={HTMLtarget}>
          {source}
        </a>
      </div>
    </div>
  );
}
