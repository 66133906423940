import React from 'react';
import { Image } from 'semantic-ui-react';
import { logos, HTMLtarget } from '../..';

export function WithLogo (url, logo) {
  const logoSource = logos[`${logo}`];

  return (
    <div className="imageToRightOfDiv">
      <Image
        href={url}
        target={HTMLtarget}
        src={logoSource}
        size="small"
        verticalAlign="top"
        spaced="left"
      />
    </div>
  );
}
