import React from 'react';
import { Card, CardContent, Image, Modal } from 'semantic-ui-react';
// import maleFemaleImage from '../images/male_female.svg';
// import nurse from '../images/nurse.png';
import { MessageBox } from './react-chat-elements';

const Message = ({
  currentUser,
  displayName,
  messageText,
  messageDate,
  role,
  file,
  filetype
}) => {
  return (
    <>
      {file ? (
        <div className={currentUser ? 'message myMessage' : 'message'}>
          <Card
            style={{ margin: '10px' }}
            className={currentUser ? 'rce-card-right-image' : ''}>
            <CardContent style={{ height: '100%' }}>
              <Modal
                closeIcon
                trigger={<Image src={file} className="withPointer" />}>
                <Image src={file} />
              </Modal>
            </CardContent>
            <Card.Content extra>
              <Card.Meta>Lähettäjä: {displayName}</Card.Meta>
            </Card.Content>
          </Card>
        </div>
      ) : (
        <div className={currentUser ? 'message myMessage' : 'message'}>
          <MessageBox
            title={currentUser ? '' : displayName}
            text={messageText}
            date={messageDate}
            notch={true}
            position={currentUser ? 'right' : 'left'}
            professional={
              role === 'nurse' || role === 'doctor' ? 'professional' : null
            }
            // status={'sent'} // waiting, sent, received, read ....
            // avatar={
            //   role === 'nurse' || role === 'doctor' ? nurse : maleFemaleImage
            // }
          />
        </div>
      )}
    </>
  );
};
export default Message;
